<template>
	<b-card-group>
		<b-card>
			<b-row>
				<b-col>
					<h4 class="mb-4">
						{{ translate('address_book') }}
					</h4>
				</b-col>
				<b-col>
					<b-button
						:to="{name: 'NewProfileAddress'}"
						variant="primary"
						class="float-right">
						{{ translate('new_address') }}
					</b-button>
				</b-col>
			</b-row>
			<hr>
			<h5 class="text-muted">
				{{ translate('recurring_shipping_address') }}
			</h5>
			<address-grid
				:data="recurring"
				:loading="addressLoading"
				background="light"
				type="recurring"
				@recurring="setRecurring"
				@delete="deleteAddress"
				@edit="editAddress" />
			<hr>
			<h5 class="text-muted">
				{{ translate('additional_addresses') }}
			</h5>
			<address-grid
				:data="additional"
				:loading="addressLoading"
				@recurring="setRecurring"
				@delete="deleteAddress"
				@edit="editAddress" />
			<template v-if="allowChangeCountry.includes($user.details().country.iso_code_2)">
				<hr>
				<h5 class="text-muted">
					{{ translate('billing_addresses') }}
				</h5>
				<address-grid
					:data="billing"
					:loading="addressLoading"
					@delete="deleteAddress"
					@edit="editAddress" />
			</template>
			<template v-if="distributor.includes($user.details().type)">
				<hr>
				<h5 class="text-muted">
					{{ translate('gift_addresses') }}
				</h5>
				<address-grid
					:data="gift"
					:loading="addressLoading"
					@recurring="setRecurring"
					@delete="deleteAddress"
					@edit="editAddress" />
			</template>
		</b-card>
	</b-card-group>
</template>
<script>

import { Profile, Validations, Countries } from '@/translations';
import { SHIPPING } from '@/settings/AddressBook';
import AddressBook from '@/util/AddressBook';
import AddressGrid from './components/AddressGrid.vue';
import EventBus from '@/util/eventBus';
import { distributor } from '@/settings/Roles';
import { ALLOW_CHANGE_BILLING_COUNTRY } from '@/settings/AddressForm';

export default {
	name: 'AddressBook',
	components: { AddressGrid },
	messages: [Profile, Validations, Countries],
	data() {
		return {
			alert: new this.$Alert(),
			address: {},
			type: SHIPPING,
			allowChangeCountry: ALLOW_CHANGE_BILLING_COUNTRY,
			addressBook: new AddressBook(),
			recurring: [],
			additional: [],
			billing: [],
			gift: [],
			distributor,
		};
	},
	computed: {
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		addressErrors() {
			return this.addressBook.data.errors;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.addressBook.getAddresses(this.$user.details().id, { include_gift_addresses: true }).then(() => {
				this.transformData();
			});
		},
		setRecurring(address) {
			this.address = address;
			this.addressBook.updateRecurringAddress(this.$user.details().id, this.address.id, { type: SHIPPING }).then(() => {
				this.alert.toast('success', this.translate('address_recurring_updated'), { timer: 6000 });
				this.getData();
			}).catch(() => {
				this.alert.toast('error', this.translate('something_went_wrong'));
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
		deleteAddress(address) {
			const trans = {
				title: this.translate('delete_address_title'),
				text: this.translate('delete_address_text', { address: address.attributes.address }),
			};
			const config = {
				confirmButtonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};

			this.alert.confirmation(trans.title, trans.text, config, true).then(() => {
				this.addressBook.deleteAddress(this.$user.details().id, address.id).then(() => {
					this.alert.toast('success', this.translate('address_deleted'));
					this.getData();
				}).catch(() => {
					this.alert.toast('error', this.translate('something_went_wrong'));
				}).finally(() => {
					EventBus.$emit('updateAccountSteps');
				});
			}).catch(() => {});
		},
		editAddress(addressID) {
			this.$router.push({ name: 'UpdateProfileAddress', params: { address_id: addressID } });
		},
		transformData() {
			this.recurring = [];
			this.additional = [];
			this.billing = [];
			this.addressData.forEach((item) => {
				if (!item.attributes.default_shipping && item.attributes.only_billing_address) {
					this.billing.push(item);
				} else if (!item.attributes.default_shipping && !item.attributes.is_gift_address) {
					this.additional.push(item);
				} else if (!item.attributes.default_shipping && item.attributes.is_gift_address) {
					this.gift.push(item);
				} else if (item.attributes.default_shipping && Object.keys(item).length) {
					this.recurring.push(item);
				}
			});
		},
	},
};
</script>
